
#propuesta{
    position: absolute;
    top: 27%;
    left: 30%;
    color: white;
    text-align: center;
    font-family: 'Amperzand';
    font-style: 'italic';
}
#cliente{ 
    position: absolute;
    top: 19%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align:  center;
}

#aviso{
    position: absolute;
    top: 41%;
    left: 44%;
}

#avisotexto{
    position: absolute;
    top: 40%;
    left: 5%;
    font-size: 15px;
    padding: 55px;
}


@media (max-width: 1050px) {
    /* Aquí puedes definir los estilos que se aplicarán en pantallas de al menos 1050px de ancho */
    #propuesta{
        font-size: 13px;
        color: white;
        left: 28%;
    }
    #cliente{
        font-size: 8px;
    }
    #aviso{
        font-size: 8px;
        position: absolute;
        top: 36%;
        left: 40%;
    }
    
    #avisotexto{
        font-size: 14px;
        position: absolute;
        top: 40%;
    }
  }

