

#cargandoid {
   position: relative;
   top: -40px;
   left: -30px;
}

#cargandoidtxt {
   font-size: 8px;
}

#containerTamaño {
   width: 60%;
   left: 20%;
   position: absolute;
   display: flex;
   flex-direction: column;
}

#avisotextov2 {
   position: absolute;
   top: 60%;
}

#rechazarv2,
#aceptarv2 {
   position: absolute;
}

#rechazarv2 {
   left: 56%;
}

#aceptarv2 {
   top: 10%;
   left: 35%;
}

/* Media Queries */
@media (max-width: 1920px) {
   #avisotextov2 {
      left: 18%;
   }
   #rechazarv2 {
      background-color: yellow;
      width: 245px;
      height: 50px;
      top: 81%;
   }
   #aceptarv2 {
      background-color: aqua;
      width: 245px;
      height: 50px;
      top: 81%;
   }
}

@media (max-width: 1450px) {
   #avisotextov2 {
      left: 18%;
   }
   #rechazarv2 {
      width: 180px;
      height: 60px;
      top: 72%;
   }
   #aceptarv2 {
      width: 180px;
      height: 50px;
      top: 75%;
   }
}

@media (max-width: 1350px) {
   #avisotextov2 {
      left: 15%;
      top: 50%;
   }
   #rechazarv2 {
      width: 180px;
      height: 60px;
      top: 58%;
   }
   #aceptarv2 {
      width: 180px;
      height: 50px;
      top: 59%;
   }
}

@media (max-width: 1050px) {
   #avisotextov2 {
      left: 13%;
      top: 16%;
      font-size: 5px;
   }
   #rechazarv2 {
      width: 60px;
      height: 20px;
      top: 20%;
   }
   #aceptarv2 {
      width: 60px;
      height: 20px;
      top: 20%;
   }
   #containerTamaño {
      width: 100%;
      left: 0%;
   }
}
