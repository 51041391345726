#aprobar{
    position: absolute;
    left: 80px;
    width: 25%;
    height: 80px;
    top: 81%; 
}
#pago{
    position: absolute;
    left: 80px;
    width: 25%;
    height: 80px;
    top: 87%; 
}

#Termino{
    position: absolute;
    left: 80px;
    width: 85%;
    height: 80px;
    top: 77%; 
}

#contenedorpago{
    position: absolute;
    left: 80px;
    width: 85%;
    height: 80px;
    top: 84%; 
}

@media (max-width: 1450px) {
    
    #Terminotext{
        position: absolute;
        left: -1px;
        width: 85%;
        height: 80px;
        font-size: 16px;
    }

    #pagotext{
        position: absolute;
        left: -1px;
        width: 85%;
        height: 80px;
        top: -15%; 
        font-size: 16px;
    }
    #aprobar{
        position: absolute;
        left: 80px;
        width: 20%;
        height: 50px;
        top: 81%; 
    }
    #pago{
        position: absolute;
        left: 80px;
        width: 20%;
        height: 50px;
        top: 87%; 
    }
    
}

@media (max-width: 1050px) {
    /* Aquí puedes definir los estilos que se aplicarán en pantallas de al menos 1050px de ancho */
    #aprobar{
        position: absolute;
        left: 35px;
        width: 21%;
        height: 20px;
        top: 80%; 
    }
    #pago{
        position: absolute;
        left: 35px;
        width: 22%;
        height: 20px;
        top: 86%; 
    }

    #Terminotext{
        position: absolute;
        left: -45px;
        width: 85%;
        height: 80px;
        top: -18%; 
        font-size: 9px;
    }

    #pagotext{
        position: absolute;
        left: -45px;
        width: 85%;
        height: 80px;
        top: -15%; 
        font-size: 9px;
    }


    
  }

