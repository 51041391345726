#ContenedorPropuesta {
    border-bottom: 1px solid #D02E26;
    /* Grosor, tipo y color del borde */
    padding-bottom: 0px;
    /* Espacio para que el texto no quede justo al borde */
    top: 0%;
    left: 5%;
    width: 90%;
    position: absolute;
}

#ContenedorPropuestaTexto {
    left: 0%;
    position: absolute;
    top: -40px;
}
#PropuestaTexto{
    position: absolute;
    left: 5px;
}

#ContenedorCotizacion{
    position: absolute;
    top: 60px;
    left: 10%;
}

#ContenedorCotizacionv1{
    text-align: justify;
    display: block;
}

#ContenedorCotizacionv2{
    text-align: justify;
    display: block;
}

#ContenedorCliente{
    position: absolute;
    top: 15%;
    left: 1%;
}

#ContenedorPresentacion{
    position: absolute;
    width: 80%;
    top: 25%;
    left: 120px;
}
#Presentacion{
    text-align: justify;
    display: block;
}

#ContenedorFirma{
    position: absolute;
    top: 60%;
}

#Firma{
    display: block;
    text-align: justify;
    position: relative;
   
}
#imagenFirma{
    position: relative;
    display: block;
    top: 40px;
}

@media (max-width: 1450px) {

    #ContenedorCotizacion{
        position: absolute;
        top: 45px;
        left: 24%;
    }
    


    #ContenedorCotizacionv2{
        text-align: justify;
        display: block;
        position: relative;
        left: -10%;
    }    
    #ContenedorCotizacionv1{
        text-align: justify;
        display: block;
        position: relative;
        left: -10%;
    }
    #Presentacion{
        text-align: justify;
        display: block;
        font-size: 16px;
    }
    #Firma{
        font-size: 16px;
    }
    #ContenedorClienteTexto{
        font-size: 22px;
        position: relative;
    
    }
}


@media (max-width: 1350px) {
    #ContenedorCotizacion{
        position: absolute;
        top: 45px;
        left: 24%;
    }
    #ContenedorCotizacionv2{
        text-align: justify;
        display: block;
        position: relative;
        left: -10%;
    }    
    #ContenedorCotizacionv1{
        text-align: justify;
        display: block;
        position: relative;
        left: -10%;
    }
    #Presentacion{
        text-align: justify;
        display: block;
        font-size: 16px;
    }
    #Firma{
        font-size: 16px;
    }
    #ContenedorClienteTexto{
        font-size: 22px;
        position: relative;
    
    }
    #ContenedorFirma{
        position: absolute;
        top: 68%;
        left: 50px;
    }
}

@media (max-width: 1050px) {
    /* Aquí puedes definir los estilos que se aplicarán en pantallas de al menos 1050px de ancho */

    #ContenedorPropuesta {
        border-bottom: 1px solid #ff0606;
        /* Grosor, tipo y color del borde */
        padding-bottom: 0px;
        /* Espacio para que el texto no quede justo al borde */
        top: -20%;
        left: 5%;
        width: 90%;
        position: absolute;
    }

    #ContenedorPropuestaTexto {
        position: absolute;
        top: -15px;
        
    }
    #PropuestaTexto{
        font-size: 25px;
        position: absolute;
        left: 5px;
    }

    #ContenedorCotizacion{
        position: absolute;
        top: 20px;
        left: 10%;
    }
    
    #ContenedorCotizacionv1{
        text-align: justify;
        display: block;
        font-size: 20px;
    }
    
    #ContenedorCotizacionv2{
        text-align: justify;
        display: block;
        font-size: 20px;
    }
    
    #ContenedorCliente{
        position: absolute;
        top: 1%;
        left: 1%;
    }
    
    #ContenedorClienteTexto{
        font-size: 20px;
    }

    #ContenedorPresentacion{
        position: absolute;
        top: 20%;
        left: 1px;
        width: 100%;
    }
    #Presentacion{
        font-size: 20px;
        text-align: justify;
        display: block;
    }


    
#ContenedorFirma{
    border-top: none;
    border-bottom: none;
    position: absolute;
    top: 58%;
    left: 1px;
}

#Firma{
    font-size: 20px;
    display: block;
    text-align: justify;
    position: relative;
}
#imagenFirma{
    position: relative;
    display: block;
    top: 20px;
}
#imagenF{
    width: 25px;
    height: auto;
}


}



@media (max-width: 850px) {
    #ContenedorCotizacionv1{
        text-align: justify;
        display: block;
        font-size: 10px;
    }
    
    #ContenedorCotizacionv2{
        text-align: justify;
        display: block;
        font-size: 10px;
    }
    #Firma{
        font-size: 10px;
    }

    #Presentacion{
        font-size: 10px;
        text-align: justify;
        display: block;
    }
    #ContenedorClienteTexto{
        font-size: 10px;
        position: relative;
    }
}

@media (max-width: 850px) {
    #ContenedorCotizacionv1{
        text-align: justify;
        display: block;
        font-size: 10px;
    }
    
    #ContenedorCotizacionv2{
        text-align: justify;
        display: block;
        font-size: 10px;
    }
    #Firma{
        font-size: 10px;
    }

    #Presentacion{
        font-size: 10px;
        text-align: justify;
        display: block;
    }
    #ContenedorClienteTexto{
        font-size: 10px;
        position: relative;
    }
}


@media (max-width: 520px) {
    #ContenedorCotizacionv1{
        text-align: justify;
        display: block;
        font-size: 6px;
    }
    
    #ContenedorCotizacionv2{
        text-align: justify;
        display: block;
        font-size: 6px;
    }
    #Firma{
        font-size: 6px;
    }

    #Presentacion{
        font-size: 6px;
        text-align: justify;
        display: block;
    }
    #ContenedorClienteTexto{
        font-size: 6px;
        position: relative;
    }
}


@media (max-width: 380px) {
    #ContenedorCotizacionv1{
        text-align: justify;
        display: block;
        font-size: 4px;
    }
    
    #ContenedorCotizacionv2{
        text-align: justify;
        display: block;
        font-size: 4px;
    }
    #Firma{
        font-size: 4px;
    }

    #Presentacion{
        font-size: 4px;
        text-align: justify;
        display: block;
    }
    #ContenedorClienteTexto{
        font-size: 4px;
        position: relative;
    }
}